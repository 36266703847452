@import 'styles/media.scss';
@import 'styles/flex.scss';

.ContentTitle {
    @extend .flex-between;

    @include media('<=Lphone') {
        flex-direction: column;
    }

    .ButtonsPlace {
        @extend .flex-between;
        margin-top: 10px;
    }
}
